<template>
  <div>
    <b-row>
      <b-col md="7" lg="8" xl="8">
        <b-card>
          <b-row>
            <b-col v-for="(item, index) in media" md="3" :key="index">
              <input-file
                :key="item.id"
                :ref="item.slug"
                :data="item"
                @open_editor="openEditor"
              ></input-file>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="5" lg="4" xl="4">
        <b-card>
          <b-img
            :src="require('@/assets/images/pages/hall/center.jpg')"
            style="width: 100%;"
          >
          </b-img>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="7" lg="8" xl="8">
        <b-card :title="$t('hall.left')">
          <b-row>
            <b-col v-for="(item, index) in left" md="4" :key="index">
              <input-file
                :key="item.id"
                :ref="item.slug"
                :data="item"
                @open_editor="openEditor"
              ></input-file>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="5" lg="4" xl="4">
        <b-card>
          <b-img
            :src="require('@/assets/images/pages/hall/left.jpg')"
            style="width: 100%;"
          >
          </b-img>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="7" lg="8" xl="8">
        <b-card :title="$t('hall.right')">
          <b-row>
            <b-col v-for="(item, index) in right" md="4" :key="index">
              <input-file
                :key="item.id"
                :ref="item.slug"
                :data="item"
                @open_editor="openEditor"
              ></input-file>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="5" lg="4" xl="4">
        <b-card>
          <b-img
            :src="require('@/assets/images/pages/hall/right.jpg')"
            style="width: 100%;"
          >
          </b-img>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card>
          <b-img
            :src="require('@/assets/images/pages/hall/all.jpg')"
            style="width: 100%;"
          >
          </b-img>
        </b-card>
      </b-col>
    </b-row>

    <modal-editor
      :image="file"
      :type="type"
      :width="width"
      :height="height"
      @onSave="showPreview"
      @close="abortImageEdit"
    >
    </modal-editor>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { BCard, BRow, BCol, BImg } from "bootstrap-vue";
import { getAllPersonalizations } from "@api/personalizations";
import ModalEditor from "../editor.vue";
import inputFile from "./input-img-hall.vue";

export default {
  components: {
    BCardCode,
    BCard,
    BRow,
    BCol,
    BImg,
    ModalEditor,
    inputFile,
  },
  data() {
    return {
      left: [],
      right: [],
      media: [],
      element_type: {
        hall_tv: { ratio: 0.5625, width: 256 },
        hall_logo_desk: { ratio: 0.183, width: 720 },
        hall_panel_a1: { ratio: 1.501, width: 256 },
        hall_panel_a2: { ratio: 1.501, width: 256 },
        hall_panel_b1: { ratio: 1.501, width: 256 },
        hall_panel_b2: { ratio: 1.501, width: 256 },
        hall_panel_c1: { ratio: 1.501, width: 256 },
        hall_panel_c2: { ratio: 1.501, width: 256 },
        hall_panel_d1: { ratio: 1.501, width: 256 },
        hall_panel_d2: { ratio: 1.501, width: 256 },
        hall_panel_e1: { ratio: 1.501, width: 256 },
        hall_panel_e2: { ratio: 1.501, width: 256 },
        hall_panel_f1: { ratio: 1.501, width: 256 },
        hall_panel_f2: { ratio: 1.501, width: 256 },
        hall_sponsor_a: { ratio: 2.673, width: 256 },
        hall_sponsor_b: { ratio: 2.673, width: 256 },
      },
      file: null,
      type: "",
      width: 720,
      height: 1280,
    };
  },
  created() {
    this.getHall();
  },
  methods: {
    getHall() {
      const $this = this;
      getAllPersonalizations()
        .then((data) => {
          $this.hallData(data.hall);
        })
        .catch(() => {
          $this.$router.push({ path: `/error-404/` });
        });
    },
    hallData(data) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (
          element.slug == "hall_tv" ||
          element.slug == "hall_logo_desk" ||
          element.slug == "hall_sponsor_a" ||
          element.slug == "hall_sponsor_b"
        ) {
          this.media.push(element);
          continue;
        }
        if (
          element.slug == "hall_panel_a1" ||
          element.slug == "hall_panel_b1" ||
          element.slug == "hall_panel_c1" ||
          element.slug == "hall_panel_d1" ||
          element.slug == "hall_panel_e1" ||
          element.slug == "hall_panel_f1"
        ) {
          this.left.push(element);
          continue;
        }
        if (
          element.slug == "hall_panel_a2" ||
          element.slug == "hall_panel_b2" ||
          element.slug == "hall_panel_c2" ||
          element.slug == "hall_panel_d2" ||
          element.slug == "hall_panel_e2" ||
          element.slug == "hall_panel_f2"
        ) {
          this.right.push(element);
          continue;
        }
      }
    },
    openEditor(file, type) {
      if (file != null) {
        this.file = file;
        this.type = type;
        this.aspect_ratio = this.element_type[type].ratio;
        this.height = this.aspect_ratio * this.element_type[type].width;
        this.width = this.element_type[type].width;
        this.$bvModal.show("modal-editor");
      }
    },
    showPreview(type, imageUrl, image) {
      this.$refs[type][0].file = image;
      this.$refs[type][0].preview_image = imageUrl;
      this.$refs[type][0].saveImage();
    },
    abortImageEdit(type) {
      this.$refs[type][0].file = null;
    },
  },
};
</script>

<style>
.preview-img-setting img {
  width: 200px;
  position: relative;
  left: 50%;
  top: 50%;
  padding-top: 10px;
  transform: translate(-50%, 0%);
}

.preview-img-setting {
  max-width: 250px;
  max-height: 250px;
  margin-top: 10px;
}
</style>
